import { render, staticRenderFns } from "./MemberFollow.vue?vue&type=template&id=23f08676&scoped=true"
import script from "./MemberFollow.vue?vue&type=script&lang=js"
export * from "./MemberFollow.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23f08676",
  null
  
)

component.options.__file = "MemberFollow.vue"
export default component.exports